/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  TableContainer,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from './styles';
import projectDeveloperRequest from '../../../requests/api/project_developer';
import projectCellActions from '../../../actions/projectCellActions';

function CurrentAssignments({
  className,
  currentMonthAssignment,
  setRefreshCurrentAssignment,
  setRefreshCharts,
  projects,
  allProjectRoles,
  month,
  currentMonthAvailability,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [projectDevelopers, setProjectDevelopers] = useState([]);
  const [editId, setEditId] = useState(0);
  const [assignedHours, setAssignedHours] = useState(0);
  const [projectRoles, setProjectRoles] = useState([]);
  const [resetProjectRoles, setResetProjectRoles] = useState(false);
  const [monthAvailability, setMonthAvailability] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const getMonth = (date) => {
    const dateFormat = new Date(date);
    const Month = dateFormat.toLocaleString('es-es', { month: 'long' });
    return Month;
  };

  useEffect(() => {
    const setVariables = () => {
      const arrayProjectRoles = currentMonthAssignment[0]?.project_developers.map((e) => e.project_roles);
      setProjectRoles(arrayProjectRoles || []);
      setProjectDevelopers(currentMonthAssignment[0]?.project_developers || []);
    };
    setVariables();
  }, [currentMonthAssignment, resetProjectRoles]);

  useEffect(() => {
    const getMonthAvailability = () => {
      if (currentMonthAvailability.length > 0) {
        setMonthAvailability(currentMonthAvailability[0]);
      } else {
        setMonthAvailability(null);
      }
    };
    getMonthAvailability();
  }, [currentMonthAvailability]);

  if (projects.length === 0) {
    return null;
  }

  const editProjectDeveloper = async (index) => {
    try {
      const data = {
        id: editId,
        projectRoles: projectRoles[index],
        assignedHours,
      };
      const response = await projectDeveloperRequest.putEditProjectDeveloper(data);

      if (response.success) {
        enqueueSnackbar('Cambios guardados', {
          variant: 'success',
        });
        setRefreshCurrentAssignment((prevState) => !prevState);
        setRefreshCharts((prevState) => !prevState);
        setEditId(0);
      } else {
        enqueueSnackbar(response.message, {
          variant: 'error',
          action: (
            <Button href="/administracion/asignaciones">
              Volver a asignaciones
            </Button>
          ),
        });
      }
    } catch (error) {
      enqueueSnackbar('error', {
        variant: 'error',
        action: (
          <Button href="/administracion/asignaciones">
            Volver a asignaciones
          </Button>
        ),
      });
    }
  };

  const deleteProjectDeveloper = async (id) => {
    try {
      const data = {
        id,
      };
      const response = await projectDeveloperRequest.deleteProjectDeveloper(
        data,
      );
      if (response.success) {
        enqueueSnackbar('Asignacion eliminadas!', {
          variant: 'success',
        });
        setRefreshCurrentAssignment((prevState) => !prevState);
        setEditId(0);
      } else {
        enqueueSnackbar(response.message, {
          variant: 'error',
          action: (
            <Button href="/administracion/asignaciones">
              Volver a asignaciones
            </Button>
          ),
        });
      }
    } catch (error) {
      enqueueSnackbar('error', {
        variant: 'error',
        action: (
          <Button href="/administracion/asignaciones">
            Volver a asignaciones
          </Button>
        ),
      });
    }
  };

  const handleProjectDeveloperEdit = (projectDeveloper) => {
    setEditId(projectDeveloper.id);
    setAssignedHours(projectDeveloper.assignedHours);
  };

  const handleSelectProjectRoles = (index, newSelectedValues) => {
    const newProjectRoles = [...projectRoles];
    newProjectRoles[index] = newSelectedValues;
    setProjectRoles(newProjectRoles);
  };

  const handleClickProjectName = (projectDeveloper) => {
    dispatch(
      projectCellActions.setProjectCell({
        projectId: projectDeveloper.project_cell.projectId,
        month,
      }),
    );
    history.push('/administracion/celulas-de-proyecto/panel');
  };

  return (
    <div>
      <div className={classes.cardTitle}>
        <Typography variant="h3" color="textPrimary" align="left">
          Asignaciones {getMonth(month)}
        </Typography>
      </div>
      <Card
        className={clsx(classes.cardMargin, classes.cardHeigth, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>
                    Proyecto - Cliente
                  </TableCell>
                  <TableCell className={classes.cell}>Rol</TableCell>
                  <TableCell className={classes.cell}>
                    Horas Asignadas
                  </TableCell>
                  <TableCell className={classes.cell}>Demanda Min %</TableCell>
                  <TableCell className={classes.cell}>Demanda Max %</TableCell>
                  <TableCell align="right" className={classes.cell}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDevelopers.map((e, index) => {
                  if (editId === e.id) {
                    return (
                      <TableRow hover key={e.id}>
                        <TableCell className={classes.cell}>
                          {e.project_cell.project.name}
                          {' - '}
                          {e.project_cell.project.client.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Autocomplete
                            key={index}
                            multiple
                            id="checkboxes-tags-demo"
                            options={allProjectRoles}
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            style={{ minWidth: 200 }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Roles"
                                variant="outlined"
                              />
                            )}
                            value={projectRoles[index]}
                            onChange={(event, newSelectedValues) => handleSelectProjectRoles(index, newSelectedValues)}
                          />
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <TextField
                            required
                            label="Horas Asignadas"
                            name="role"
                            variant="outlined"
                            value={assignedHours}
                            onChange={(event) => {
                              setAssignedHours(event.target.value);
                            }}
                          />
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell align="right" className={classes.cell}>
                          <div className={classes.buttonsDisplay}>
                            <DeleteIcon
                              sx={{
                                backgroundColor: 'red',
                                color: 'white',
                                fontSize: 37,
                              }}
                              className={classes.deleteButton}
                              onClick={() => deleteProjectDeveloper(e.id)}
                            />
                            <Button
                              color="secondary"
                              variant="contained"
                              className={classes.action}
                              onClick={() => editProjectDeveloper(index)}
                            >
                              Guardar
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              className={classes.action}
                              onClick={() => { setEditId(0); setResetProjectRoles((prevState) => !prevState); } }
                            >
                              Cancelar
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }

                  const demandMinHours = monthAvailability?.minHours || 0;
                  const demandMaxHours = monthAvailability?.maxHours || 0;

                  const developerAssignedHours = e.assignedHours || 0;
                  const minDemandPorcentage = parseInt((developerAssignedHours / demandMinHours) * 100, 10)
                    || 0;
                  const maxDemandPorcentage = parseInt((developerAssignedHours / demandMaxHours) * 100, 10)
                    || 0;

                  return (
                    <TableRow hover key={e.id}>
                      <TableCell
                        className={clsx(classes.cell, classes.developerBarName)}
                        onClick={() => handleClickProjectName(e)}
                      >
                        {e.project_cell.project.name}
                        {' - '}
                        {e.project_cell.project.client.name}
                      </TableCell>
                      <TableCell className={classes.cell}>{e.project_roles.map((rol) => `${rol.name}`).join(' & ')}</TableCell>
                      <TableCell className={classes.cell}>
                        {developerAssignedHours}
                      </TableCell>
                      <TableCell
                        className={classes.cell}
                      >{`${minDemandPorcentage}%`}</TableCell>
                      <TableCell
                        className={classes.cell}
                      >{`${maxDemandPorcentage}%`}</TableCell>
                      <TableCell align="right" className={classes.cell}>
                        <Button
                          color="secondary"
                          variant="contained"
                          className={classes.action}
                          onClick={() => { handleProjectDeveloperEdit(e); setResetProjectRoles((prevState) => !prevState); }}
                        >
                          Editar
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Card>
    </div>
  );
}

CurrentAssignments.propTypes = {
  className: PropTypes.string,
  currentMonthAssignment: PropTypes.array,
  setRefreshCurrentAssignment: PropTypes.func,
  setRefreshCharts: PropTypes.func.isRequired,
  projects: PropTypes.array,
  currentMonthAvailability: PropTypes.array,
  allProjectRoles: PropTypes.array,
  month: PropTypes.string,
};

export default CurrentAssignments;
