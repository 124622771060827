/* eslint-disable no-underscore-dangle */
import React, { useState, useRef } from 'react';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import axios from '../../../utils/axios';
import billRequests from '../../../requests/api/bill';

const UploadBillsModal = ({ open, handleClose, filters }) => {
  const classes = useStyles();
  const [selectedFileName, setSelectedFileName] = useState('');
  const [fileKey, setFileKey] = useState(0);
  const hiddenFileInput = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleExport = async () => {
    const { data } = await billRequests.exportBills(filters);
    if (data.data.files.length) {
      data.data.files.forEach((file) => {
        const uri = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file.file}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = uri;
        downloadLink.download = file.fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    }
  };

  const handleUpload = (event) => {
    const fileUploaded = event.target.files[0];
    const { name } = fileUploaded;

    if (!name.endsWith('.xlsx') && !name.endsWith('.xls')) {
      enqueueSnackbar('El archivo debe ser .xlsx o .xls', {
        variant: 'error',
      });
      return;
    }

    setSelectedFileName(name);
  };

  const handleDeleteFile = () => {
    setSelectedFileName('');
    setFileKey((prevKey) => prevKey + 1);
    hiddenFileInput.current.value = ''; // Limpiar el valor del input de archivo
  };

  const handleSaveChanges = async () => {
    if (!selectedFileName) {
      return;
    }

    const formData = new FormData();
    formData.append('file', hiddenFileInput.current.files[0]);
    try {
      await axios.post('/api/bill/upload-bills', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      enqueueSnackbar('Archivo subido exitosamente.', {
        variant: 'success',
      });
      handleClose();
    } catch (err) {
      enqueueSnackbar(err.response.data.errorMessage, {
        variant: 'error',
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modalContainer}>
      <div>
        <div className={classes.modalContent}>
          <div className={classes.modalTitleContainer}>
            <Typography className={classes.modalTitle}>
              Cargar Facturas
            </Typography>
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          </div>
          <div className={classes.modalButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              className={classes.uploadButton}
            >
              Descargar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              className={classes.uploadButton}
            >
              Subir Archivo
            </Button>
            <input
              key={fileKey}
              type="file"
              onChange={handleUpload}
              ref={hiddenFileInput}
              style={{ display: 'none' }}
            />
          </div>
          {selectedFileName && (
            <>
              <Typography className={classes.selectedFileName}>
                {selectedFileName}
                <CloseIcon
                  className={classes.deleteButton}
                  onClick={handleDeleteFile}
                />
              </Typography>
            </>
          )}
        </div>
        <div className={classes.saveChangesContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveChanges}
          >
            Guardar Cambios
          </Button>
        </div>
      </div>
    </Modal>
  );
};

UploadBillsModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default UploadBillsModal;
