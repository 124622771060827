import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Modal,
  Typography,
} from '@material-ui/core';
import availabilityRequest from '../../../requests/api/availability';
import useStyles from './styles';

export default function EditDefaultHoursModal({
  editEveryAvailability,
  setEditEveryAvailability,
  defaultValuesToEdit,
  setReloadAvailabilities,
  developerId,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [availabilitiesToUpdate, setAvailabilitiesToUpdate] = useState([]);

  useEffect(() => {
    const getFutureAvailabilities = async () => {
      const response = await availabilityRequest.get12AvailabilitiesPerDev({
        developerId,
        onlyFuture: true,
      });
      if (response.success) setAvailabilitiesToUpdate(response.data.data.availabilities);
    };
    getFutureAvailabilities();
  }, [developerId]);

  const handleEditNotManuallyEditedAvailabilityHours = async () => {
    try {
      const nonEditedAvailabilities = availabilitiesToUpdate.filter((a) => !a.manuallyEdited);
      const availabilitiesToUpdateIds = nonEditedAvailabilities.map((el) => el.id);

      const response = await availabilityRequest.putAvailabilitiesByDeveloper({
        availabilitiesToUpdateIds,
        minHours: defaultValuesToEdit.minDefaultHours,
        maxHours: defaultValuesToEdit.maxDefaultHours,
        developerId,
      });
      if (response.success) {
        enqueueSnackbar('Cambios guardados en las disponibilidades no editadas manualmente', {
          variant: 'success',
        });
        setEditEveryAvailability(false);
        setReloadAvailabilities(true);
      } else {
        enqueueSnackbar('No se pudieron guardar los cambios. Asegurate que el desarrollador esté considerado para los meses a los que se le aplicará el cambio.', { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(err, { variant: 'error' });
    }
  };

  const handleEditEveryAvailabilityHours = async () => {
    try {
      const availabilitiesToUpdateIds = availabilitiesToUpdate.map((el) => el.id);

      const response = await availabilityRequest.putAvailabilitiesByDeveloper({
        availabilitiesToUpdateIds,
        minHours: defaultValuesToEdit.minDefaultHours,
        maxHours: defaultValuesToEdit.maxDefaultHours,
        developerId,
      });
      if (response.success) {
        enqueueSnackbar('Cambios en las horas de todas las disponibilidades guardados', {
          variant: 'success',
        });
        setEditEveryAvailability(false);
        setReloadAvailabilities(true);
      } else {
        enqueueSnackbar('No se pudieron guardar los cambios. Asegurate que el desarrollador esté considerado para los próximos meses.', { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(err, { variant: 'error' });
    }
  };

  return (
    <Box
      mt={2}
      display='flex'
      flexDirection='column'
      gridGap={10}
    >
      <Modal
        open={editEveryAvailability}
        onClose={() => setEditEveryAvailability(false)}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='75%'
        >
          <Card className={classes.modal}>
            <CardContent className={classes.modalContent}>
              <Box>
                <Box>
                  <Box
                    display='flex'
                    mb={2}
                  >
                    <Typography
                      variant='h3'
                    >
                      Cambio de disponibilidad en meses editados
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box mt='8%' maxWidth='75%'>
                      <Typography
                        variant='h5'
                      >
                        ¿Quieres que el cambio de disponibilidad default
                        se aplique también a los meses que editaste manualmente?
                      </Typography>
                    </Box>
                    <Box my='8%' maxWidth='75%'>
                      <Typography
                        variant='h5'
                      >
                        Si confirmas esta acción, todos los meses
                        quedaran con el mismo rango de horas de disponibilidad.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                display='flex'
                justifyContent='space-between'
                padding='10px 24px'
                mt='3%'
              >
                <Box width={'max-content'} mr={2}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={() => setEditEveryAvailability(false)}
                    variant='contained'
                  >
                    Cancelar
                  </Button>
                </Box>
                <Box width={'max-content'}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={handleEditEveryAvailabilityHours}
                    variant='contained'
                  >
                    Aplicar a todos
                  </Button>
                </Box>
                <Box width={'max-content'}>
                  <Button
                    fullWidth
                    color="secondary"
                    onClick={handleEditNotManuallyEditedAvailabilityHours}
                    variant='contained'
                  >
                    Aplicar a no editados
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
}

EditDefaultHoursModal.propTypes = {
  editEveryAvailability: PropTypes.bool,
  setEditEveryAvailability: PropTypes.func,
  defaultValuesToEdit: PropTypes.object,
  setReloadAvailabilities: PropTypes.func,
  developerId: PropTypes.number,
  user: PropTypes.object,
};
