import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Grid,
  Button,
  IconButton,
  SvgIcon,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import useStyles from './styles';
import Request from '../../../requests/api/developer';
import EditDefaultHoursModal from './EditDefaultHoursModal';

function DefaultAvailability({
  developer, setReloadAvailabilities,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [enableEdit, setEnableEdit] = useState(false);
  const [editEveryAvailability, setEditEveryAvailability] = useState(false);
  const [defaultValuesToEdit, setDefaultValuesToEdit] = useState({
    minDefaultHours: '',
    maxDefaultHours: '',
    maxConcurrentProjects: '',
  });

  useEffect(() => {
    setDefaultValuesToEdit({
      minDefaultHours: developer?.minDefaultHours,
      maxDefaultHours: developer?.maxDefaultHours,
      maxConcurrentProjects: developer?.maxConcurrentProjects,
    });
  }, [developer]);

  const handleConfirmEdit = async () => {
    const response = await Request.putDeveloperById({
      id: developer.id,
      contractType: developer.contractType,
      clockifyApiKey: developer.clockifyApiKey,
      jobTitle: developer.jobTitle,
      github: developer.github,
      linkedin: developer.linkedin,
      costPerHour: developer.costPerHour || null,
      contractHours: developer.contractHours,
      contractStartDate: developer.contractStartDate,
      minDefaultHours: defaultValuesToEdit.minDefaultHours,
      maxDefaultHours: defaultValuesToEdit.maxDefaultHours,
      maxConcurrentProjects: defaultValuesToEdit.maxConcurrentProjects,
    });

    if (response.success) {
      enqueueSnackbar('Cambios en las horas por defecto guardados', {
        variant: 'success',
      });
      setEnableEdit(false);
      setEditEveryAvailability(true);
    }
  };

  return (
    <Box
      className={clsx(classes.containerWithShadow, classes.searchBox)}
    >
      <Grid
        container
        spacing={3}
        className={classes.filtersContainer}
        display="flex"
        alignItems="center"
        flexwrap="wrap"
      >
        <Grid item md={3.5} xs={12}>
          <TextField
            onChange={(e) => setDefaultValuesToEdit((prev) => ({
              ...prev,
              minDefaultHours: e.target.value,
            }))}
            disabled={!enableEdit}
            value={defaultValuesToEdit.minDefaultHours}
            variant="outlined"
            label='Horas mínimas'
            fullWidth
          />
        </Grid>
        <Grid item md={3.5} xs={12}>
          <TextField
            onChange={(e) => setDefaultValuesToEdit((prev) => ({
              ...prev,
              maxDefaultHours: e.target.value,
            }))}
            disabled={!enableEdit}
            value={defaultValuesToEdit.maxDefaultHours}
            variant="outlined"
            label='Horas máximas'
            fullWidth
          />
        </Grid>
        <Grid item md={3.5} xs={12}>
          <TextField
            onChange={(e) => setDefaultValuesToEdit((prev) => ({
              ...prev,
              maxConcurrentProjects: e.target.value,
            }))}
            disabled={!enableEdit}
            value={defaultValuesToEdit.maxConcurrentProjects}
            variant="outlined"
            label='Máximo de proyectos paralelos'
            fullWidth
          />
        </Grid>
        <Grid item md={1.5} xs={12}>
          {enableEdit
            ? <Box
              width='max-content'
              display='flex'
              justifyContent='space-between'
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#00D293',
                  '&:hover': {
                    backgroundColor: '#05a676',
                  },
                }}
                onClick={() => { handleConfirmEdit(); }}
              >
                Confirmar
              </Button>
              <IconButton
              onClick={() => setEnableEdit(false)}
              >
                <SvgIcon>
                  <CancelIcon sx={{ color: 'red' }}/>
                </SvgIcon>
              </IconButton>
            </Box>
            : <Button
              variant="contained"
              sx={{
                backgroundColor: '#24335E',
                '&:hover': {
                  backgroundColor: '#152245',
                },
              }}
              onClick={() => setEnableEdit(true)}
            >
              Editar
            </Button>}
        </Grid>
      </Grid>
      {editEveryAvailability && <EditDefaultHoursModal
        editEveryAvailability={editEveryAvailability}
        setEditEveryAvailability={setEditEveryAvailability}
        defaultValuesToEdit={defaultValuesToEdit}
        setReloadAvailabilities={setReloadAvailabilities}
        developerId={developer.id}
      />}
    </Box>
  );
}

DefaultAvailability.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  developer: PropTypes.object.isRequired,
  setReloadAvailabilities: PropTypes.func,
};
export default DefaultAvailability;
