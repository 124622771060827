import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import * as Yup from 'yup';
import billRequest from '../../../requests/api/bill';
import useStyles from './styles';
import { ISSUED, PAID, CANCELED } from '../../../utils/const/billStatus';
import { CLP, currencyList } from '../../../utils/const/currencyTypes';
import { billTypesList } from '../../../utils/const/billTypes';
import { billCompaniesList } from '../../../utils/const/billCompanies';
import { billServicesTypesList } from '../../../utils/const/billServicesTypes';

const statusOptions = [ISSUED, PAID, CANCELED];

function BillEditForm({
  className, bill, clients, ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [paymentDateRequired, setPaymentDateRequired] = useState(false);
  const [creditNoteUrlRequired, setCreditNoteUrlRequired] = useState(false);

  useEffect(() => {
    setAllClients(clients);
  }, [clients]);

  if (submitSuccess) {
    return <Redirect to="/administracion/facturas" />;
  }

  if (Object.keys(bill).length === 0) {
    return null;
  }

  const validationSchema = Yup.object().shape({
    company: Yup.string().required('Empresa es requerida'),
    type: Yup.string().required('Tipo de factura es requerido').test(
      'company-type-validation',
      (value, context) => {
        const { company } = context.parent;

        if (company === 'TUBESOFT CONSULTORES DE SOFTWARE LIMITADA' && value !== 'Exenta') {
          return context.createError({
            message: 'TUBESOFT CONSULTORES DE SOFTWARE LIMITADA solo puede emitir facturas exentas de IVA',
          });
        }

        if (company === 'TUBESOFT VENTURES SPA' && value !== 'Afecta') {
          return context.createError({
            message: 'TUBESOFT VENTURES SPA solo puede emitir facturas afectas a IVA',
          });
        }

        return true;
      },
    ),
  });

  return (
    <Formik
      initialValues={{
        clientId: bill.clientId || '',
        company: bill.company || '',
        serviceType: bill.serviceType || '',
        type: bill.type || '',
        number: bill.number || '',
        issueDate: bill.issueDate ? moment(bill.issueDate).utc() : null,
        dueDate: bill.dueDate ? moment(bill.dueDate).utc() : null,
        amount: bill.amount || '',
        currency: bill.currency || CLP,
        paymentDate: bill.paymentDate ? moment(bill.paymentDate).utc() : null,
        billUrl: bill.billUrl || '',
        comments: bill.comments || '',
        status: bill.status || ISSUED,
        serviceMonth: bill.serviceMonth
          ? moment(bill.serviceMonth).utc()
          : null,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            ...bill,
            clientId: values.clientId,
            company: values.company,
            serviceType: values.serviceType,
            type: values.type,
            number: values.number,
            issueDate: values.issueDate,
            dueDate: values.dueDate,
            amount: values.amount,
            currency: values.currency,
            paymentDate: values.paymentDate,
            billUrl: values.billUrl,
            creditNoteUrl: values.creditNoteUrl,
            comments: values.comments,
            status: values.status,
            serviceMonth: values.serviceMonth,
          };
          const response = await billRequest.putBillById(data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: (
                <Button href="/administracion/talento">Volver a talento</Button>
              ),
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.emptyRoot, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allClients}
                    getOptionLabel={(option) => (option ? `${option.name}` : '')
                    }
                    value={
                      allClients.find(
                        (option) => values.clientId === option.id,
                      ) || null
                    }
                    onChange={(e, value) => {
                      setFieldValue('clientId', value?.id || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={Boolean(touched.clientId && errors.clientId)}
                        helperText={touched.clientId && errors.clientId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Cliente"
                        name="clientId"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={billCompaniesList}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.company}
                    onChange={(event, value) => {
                      setFieldValue('company', value || '');
                      // Automatically set the correct bill type based on company
                      if (value === 'TUBESOFT CONSULTORES DE SOFTWARE LIMITADA') {
                        setFieldValue('type', 'Exenta');
                      } else if (value === 'TUBESOFT VENTURES SPA') {
                        setFieldValue('type', 'Afecta');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.company && errors.company)}
                        helperText={touched.company && errors.company}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Empresa"
                        name="company"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={billServicesTypesList}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.serviceType}
                    onChange={(event, value) => {
                      setFieldValue('serviceType', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.serviceType && errors.serviceType)}
                        helperText={touched.serviceType && errors.serviceType}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Servicio"
                        name="serviceType"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={billTypesList}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.type}
                    onChange={(event, value) => {
                      setFieldValue('type', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.type && errors.type)}
                        helperText={touched.type && errors.type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Tipo Factura"
                        name="type"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.number && errors.number)}
                    helperText={touched.number && errors.number}
                    label="Número"
                    name="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.number}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={statusOptions}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.status}
                    onChange={(event, value) => {
                      setFieldValue('status', value || '');
                      if (value === PAID) {
                        setPaymentDateRequired(true);
                      }
                      if (value === CANCELED) {
                        setCreditNoteUrlRequired(true);
                      }
                      if (value !== PAID) {
                        setPaymentDateRequired(false);
                      }
                      if (value !== CANCELED) {
                        setCreditNoteUrlRequired(false);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.status && errors.status)}
                        helperText={touched.status && errors.status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Estado"
                        name="status"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      label="Fecha Emisión "
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.issueDate}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('issueDate', value);
                        } else {
                          setFieldValue('issueDate', null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      label="Fecha Vencimiento "
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.dueDate}
                      onChange={(value) => setFieldValue('dueDate', value || null)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                    label="Monto Total"
                    name="amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.amount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={currencyList}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.currency}
                    onChange={(event, value) => {
                      setFieldValue('currency', value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.currency && errors.currency)}
                        helperText={touched.currency && errors.currency}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Moneda"
                        name="currency"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      label="Fecha Pago "
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.paymentDate}
                      onChange={(value) => setFieldValue('paymentDate', value || null)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          required={paymentDateRequired}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(touched.billUrl && errors.billUrl)}
                    fullWidth
                    helperText={touched.billUrl && errors.billUrl}
                    label="URL Factura"
                    name="billUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.billUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(
                      touched.creditNoteUrl && errors.creditNoteUrl,
                    )}
                    fullWidth
                    helperText={touched.creditNoteUrl && errors.creditNoteUrl}
                    label="URL Nota de Crédito"
                    name="creditNoteUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.creditNoteUrl}
                    variant="outlined"
                    required={creditNoteUrlRequired}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(touched.comments && errors.comments)}
                    fullWidth
                    helperText={touched.comments && errors.comments}
                    label="Comentarios"
                    name="comments"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.comments}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      inputFormat="MM-yyyy"
                      views={['year', 'month']}
                      label="Mes de Servicio"
                      minDate={new Date('2020-02-01')}
                      maxDate={new Date('2030-01-01')}
                      value={values.serviceMonth}
                      onChange={(value) => setFieldValue('serviceMonth', value || null)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

BillEditForm.propTypes = {
  className: PropTypes.string,
  bill: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
};

export default BillEditForm;
