const MESSAGES = {
  WARNING: 'Un poco al debe 🤔, pero aún puedes. ¡Ánimo! 💪🏽',
  SUCCESS: 'Vas muy bien. ¡Sigue así! 😎',
  COMPLETED: 'Horas mínimas cumplidas. ¡Sigamos adelante con los objetivos! 💪🏽',
  POPOVER_TITLE: 'Consideraciones:',
  POPOVER_TEXT: `- <b>IMPORTANTE:</b> Las Horas Por Registrar en el Mes representan <b>únicamente</b> la cantidad de horas para cumplir con el <b>mínimo</b> establecido por contrato. Una vez que se cumpla con el mínimo, <b>se debe seguir trabajando</b>, independientemente de la cantidad de horas que se hayan registrado.

- Las Horas Por Registrar en el Mes se calculan restando las horas mínimas por contrato con las horas trabajadas en el mes (desde las 00:00 del primer día del mes actual hasta la hora y fecha de la consulta).

- En los días hábiles restantes siempre se incluye el día actual, hasta que este se acaba.

- Las horas a registrar por día se calculan dividiendo las Horas Por Registrar en el Mes por los Días Hábiles Restantes.

- Por lo tanto, <b>se recomienda fuertemente ver esta información al inicio de cada día laboral</b> (y no al final) para no tener una mala percepción de las Horas a Trabajar por Día.

- Para toda esta información, ya están considerados los días de vacaciones restantes (en caso de que tener) y los días feriados.`,
};

export default MESSAGES;
