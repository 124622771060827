import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Divider,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Autocomplete, IconButton, InputAdornment, SvgIcon, TextField,
} from '@mui/material';
import { formatDate } from '../../../utils/date';
import {
  PENDING,
  APPROVED,
  REJECTED,
  CANCELLED,
} from '../../../utils/const/vacationRequestStatus';
import RequestDetailModal from './RequestDetailModal';
import CancelVacationsModal from './CancelVacationsModal';
import UploadCertificateUrlModal from './UploadCertificateUrlModal';

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500,
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  blueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary,
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 8px',
    borderRadius: '8px',
  },
  redBtn: {
    backgroundColor: '#D52F2F',
    color: theme.palette.text.tertiary,
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 8px',
    borderRadius: '8px',
  },
  greenBtn: {
    backgroundColor: '#00D293',
    color: theme.palette.text.tertiary,
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 8px',
    borderRadius: '8px',
  },
  grayBtn: {
    backgroundColor: '#BDBDBD',
    color: theme.palette.text.tertiary,
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 8px',
    borderRadius: '8px',
  },
  blueRequestBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 8px',
    borderRadius: '8px',
  },
}));

function RequestsList({
  className,
  vacationRequests,
  page,
  setPage,
  limit,
  setLimit,
  searchValue,
  setSearchValue,
  totalVacationRequests,
  setReloadRequests,
  developers,
  setSelectedDeveloper,
  ...rest
}) {
  const classes = useStyles();
  const [requestDetailModal, setRequestDetailModal] = useState(false);
  const [vacationToReview, setVacationToReview] = useState({});
  const [isHovered, setIsHovered] = useState({});
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openAddUrlModal, setOpenAddUrlModal] = useState(false);
  const [vacationToCancel, setVacationToCancel] = useState({});
  const [vacationToAddCertificate, setVacationToAddCertificate] = useState({});
  const requestUser = useSelector((s) => s.account.user);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleOpenRequestDetailModal = (vacation, value) => {
    if (vacation.status === PENDING && value) {
      setRequestDetailModal(value);
      setVacationToReview(vacation);
    }
  };

  const handleSeeCertificate = (vacation) => {
    if (vacation.certificateUrl) window.open(`${vacation.certificateUrl}`);
  };

  const handleUploadLink = (vacation) => {
    setVacationToAddCertificate(vacation);
    setOpenAddUrlModal(true);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nombre developer
                </TableCell>
                <TableCell>
                  Supervisor
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Fecha inicio
                </TableCell>
                <TableCell>
                  Fecha fin
                </TableCell>
                <TableCell align='center'>
                  Días solicitados
                </TableCell>
                <TableCell align='center'>
                  Días legales
                </TableCell>
                <TableCell align='center'>
                  Días adicionales
                </TableCell>
                <TableCell align='center'>
                  Fecha firma
                </TableCell>
                <TableCell align='center'>
                  Documento firmado
                </TableCell>
                <TableCell style={{ width: '25%' }}>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                  >
                    <Box width='49%'>
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        label='Buscar por estado'
                        color="secondary"
                        variant="outlined"
                        select
                        onChange={(e) => setSearchValue(e.target.value)}
                      >
                        <MenuItem
                          key={0}
                          value={''}
                        >
                          Limpiar
                        </MenuItem>
                        <MenuItem
                          key={1}
                          value={PENDING}
                        >
                          {PENDING}
                        </MenuItem>
                        <MenuItem
                          key={2}
                          value={APPROVED}
                        >
                          {APPROVED}
                        </MenuItem>
                        <MenuItem
                          key={3}
                          value={REJECTED}
                        >
                          {REJECTED}
                        </MenuItem>
                      </TextField>
                    </Box>
                    <Box width='49%'>
                      <Autocomplete
                        fullWidth
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disablePortal
                        id="supervisor-autocomplete"
                        options={developers.length > 0 ? developers : []}
                        getOptionLabel={(option) => `${option.user.name} ${option.user.lastname}`
                        }
                        onChange={(e, value) => {
                          if (value === null) {
                            setSelectedDeveloper({
                              id: null,
                              name: null,
                              lastname: null,
                            });
                            return;
                          }
                          setSelectedDeveloper(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            label="Buscar developer"
                            name="developer"
                          />
                        )} />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vacationRequests.length > 0 && vacationRequests.map((vacation, index) => {
                let buttonClass;
                if (vacation.status === PENDING
                  && vacation.supervisor.userId !== requestUser.id) buttonClass = 'grayBtn';
                if (vacation.status === PENDING
                  && vacation.supervisor.userId === requestUser.id) buttonClass = 'blueRequestBtn';
                if (vacation.status === APPROVED) buttonClass = 'greenBtn';
                if (vacation.status === REJECTED
                  || vacation.status === CANCELLED
                  || isHovered[index]) buttonClass = 'redBtn';
                const vacationDays = vacation.developer.vacationDays
                  .filter((vac) => vac.vacationRequestId === vacation.id);
                const { creditDays, legalDays } = vacationDays.reduce((acc, curr) => {
                  if (curr.isExtra) {
                    acc.creditDays += curr.vacationCharge;
                  } else {
                    acc.legalDays += curr.vacationCharge;
                  }
                  return acc;
                }, { creditDays: 0, legalDays: 0 });

                return (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell>
                      {`${vacation.developer.user.name} ${vacation.developer.user.lastname}`}
                    </TableCell>
                    <TableCell>
                      {`${vacation.supervisor.user.name} ${vacation.supervisor.user.lastname}`}
                    </TableCell>
                    <TableCell>
                      {vacation.status}
                    </TableCell>
                    <TableCell>
                      {formatDate(vacation.startDate)}
                    </TableCell>
                    <TableCell>
                      {formatDate(vacation.endDate)}
                    </TableCell>
                    <TableCell align='center'>
                      {vacation.daysRequested}
                    </TableCell>
                    <TableCell align='center'>
                      {legalDays}
                    </TableCell>
                    <TableCell align='center'>
                      {creditDays}
                    </TableCell>
                    <TableCell align='center'>
                      {vacation.certificateSignDate ? formatDate(vacation.certificateSignDate) : '-'}
                    </TableCell>
                    <TableCell align='center'>
                      {vacation.status === APPROVED ? (
                        <IconButton
                          onClick={() => {
                            if (vacation.certificateUrl) handleSeeCertificate(vacation);
                            else handleUploadLink(vacation);
                          }}
                        >
                          <SvgIcon>
                            {vacation.certificateUrl ? <InsertDriveFileIcon style={{ color: '#00D293' }} /> : <FileUploadIcon style={{ color: '#23335C' }} />}
                          </SvgIcon>
                        </IconButton>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {vacation.status !== APPROVED && <Box
                        className={classes[buttonClass]}
                        onClick={() => (vacation.status === PENDING
                          && vacation.supervisor.userId === requestUser.id)
                          && handleOpenRequestDetailModal(vacation, true)}
                      >
                        {(vacation.status === PENDING
                          && vacation.supervisor.userId === requestUser.id) ? 'APROBAR O RECHAZAR' : ''}
                        {(vacation.status === PENDING
                          && vacation.supervisor.userId !== requestUser.id) ? 'PENDIENTE' : ''}
                        {(vacation.status === REJECTED
                          || vacation.status === CANCELLED) && vacation.status.toUpperCase()}
                      </Box>}
                      {vacation.status === APPROVED && (vacation.certificateSignDate ? (
                        <Box
                        className={classes[buttonClass]}
                      >{'APROBADA Y FIRMADA'}</Box>
                      ) : (
                        <Box
                          onMouseEnter={() => setIsHovered((prev) => ({ ...prev, [index]: true }))}
                          onMouseLeave={() => setIsHovered((prev) => ({ ...prev, [index]: false }))}
                          className={classes[buttonClass]}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setVacationToCancel(vacation);
                            setOpenCancelModal(true);
                          }}
                        >
                          {isHovered[index] ? 'CANCELAR VACACIONES' : vacation.status.toUpperCase()}
                        </Box>
                      ))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalVacationRequests}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
      <RequestDetailModal
        openDetailModal={requestDetailModal}
        setOpenDetailModal={setRequestDetailModal}
        vacationToReview={vacationToReview}
        setReloadRequests={setReloadRequests}
      />
      <CancelVacationsModal
        request={vacationToCancel}
        openCancelModal={openCancelModal}
        setOpenCancelModal={setOpenCancelModal}
      />
      <UploadCertificateUrlModal
        request={vacationToAddCertificate}
        openAddUrlModal={openAddUrlModal}
        setOpenAddUrlModal={setOpenAddUrlModal}
      />
    </Card>
  );
}

RequestsList.propTypes = {
  className: PropTypes.string,
  vacationRequests: PropTypes.array,
  page: PropTypes.number,
  setPage: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  totalVacationRequests: PropTypes.number,
  setReloadRequests: PropTypes.func,
  developers: PropTypes.array,
  setSelectedDeveloper: PropTypes.func,
};

RequestsList.defaultProps = {
  vacationRequests: [],
};

export default RequestsList;
