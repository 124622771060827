import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import billRequest from '../../../requests/api/bill';
import clientRequest from '../../../requests/api/client';
import useStyles from './styles';
import billsFiltersActions from '../../../actions/billActions';

function BillsListView() {
  const classes = useStyles();
  const [bills, setBills] = useState([]);
  const [clients, setClients] = useState([]);
  const [pageOfTable, setPageOfTable] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalBills, setTotalBills] = useState(0);
  const [filters, setFilters] = useState({
    search: '',
    client: '',
    month: null,
    status: [],
    company: '',
    serviceType: '',
  });

  const billsRedux = useSelector((state) => state.bill);
  const [reduxFilters, setReduxFilters] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const setReduxInfo = async () => {
      if (Object.keys(billsRedux.billsFilters).length > 0) {
        setFilters({ ...filters, ...billsRedux.billsFilters });
      }
      setReduxFilters(true);
    };
    setReduxInfo();
  }, []);

  useEffect(() => {
    const getBills = async () => {
      const joinStatusFilter = filters.status.map((e) => e.value).join(',');

      const response = await billRequest.getBillsPerPage({
        page: pageOfTable,
        limit,
        searchValue: filters.search,
        searchClient: filters.client.name || '',
        searchStartMonth: filters.month || '',
        searchEndMonth: '',
        searchStatus: joinStatusFilter,
        searchOrder: 'DESC',
        searchCompany: filters.company || '',
        searchServiceType: filters.serviceType || '',
      });
      if (response.success) {
        const data = await response.data.data.bills;
        const arrayData = Object.values(data);
        setBills(arrayData);
        setTotalBills(response.data.data.totalBills);
      }
    };

    const setBillsFilters = async () => {
      dispatch(billsFiltersActions.setBillsFilters(filters));
    };
    if (reduxFilters) {
      setBillsFilters();
      getBills();
    }
    getBills();
  }, [pageOfTable, limit, filters, reduxFilters]);

  useEffect(() => {
    const getAllClients = async () => {
      const response = await clientRequest.getAllClients();
      if (response.success) {
        const data = await response.data.data.clients;
        const arrayData = Object.values(data);
        setClients(arrayData);
      }
    };
    getAllClients();
  }, []);

  return (
    <Page className={classes.root} title="TubeOps | Facturas">
      <Container maxWidth={false}>
        <Header />
        {bills && (
          <Box mt={3}>
            <Results
              bills={bills}
              totalBills={totalBills}
              clients={clients}
              pageOfTable={pageOfTable}
              setPageOfTable={setPageOfTable}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default BillsListView;
