const LIMITED_SOFTWARE_CONSULTING = 'TUBESOFT CONSULTORES DE SOFTWARE LIMITADA';
const VENTURES_SPA = 'TUBESOFT VENTURES SPA';

const billCompaniesList = [
  LIMITED_SOFTWARE_CONSULTING,
  VENTURES_SPA,
];

module.exports = {
  billCompaniesList,
};
