import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Divider,
  Modal,
  Typography,
} from '@mui/material';
import { CardContent, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import vacationRequest from '../../../requests/api/vacationRequest';
import { formatDate } from '../../../utils/date';
import { APPROVED, REJECTED } from '../../../utils/const/vacationRequestStatus';

const useStyles = makeStyles(() => ({
  modal: {},
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export default function RequestDetailModal({
  openDetailModal,
  setOpenDetailModal,
  vacationToReview,
  setReloadRequests,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleReviewRequest = async (e, value) => {
    try {
      const emailStartDate = vacationToReview.startDate.split('-').reverse().join('/');
      const emailEndDate = vacationToReview.endDate.split('-').reverse().join('/');
      const response = await vacationRequest.putVacationRequestById({
        id: vacationToReview.id,
        developerId: vacationToReview.developerId,
        developerEmail: `${vacationToReview.developer.user.email}`,
        developerName: `${vacationToReview.developer.user.name} ${vacationToReview.developer.user.lastname}`,
        supervisorId: vacationToReview.supervisorId,
        status: value,
        daysRequested: vacationToReview.daysRequested,
        startDate: vacationToReview.startDate,
        endDate: vacationToReview.endDate,
        emailStartDate,
        emailEndDate,
      });
      if (response.success) {
        if (value === APPROVED) {
          const params = {
            vacationRequestId: vacationToReview.id,
            developerFullName: `${vacationToReview.developer.user.name} ${vacationToReview.developer.user.lastname}`,
            developerEmail: `${vacationToReview.developer.user.email}`,
            developerStartDate: vacationToReview.startDate,
            developerEndDate: vacationToReview.endDate,
          };
          const addVacationResponse = await vacationRequest.addVacationToCalendar(params);
          if (addVacationResponse.success) {
            enqueueSnackbar('Solicitud aprobada correctamente', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(addVacationResponse.message, { variant: 'error' });
          }
        } else {
          enqueueSnackbar('Solicitud rechazada correctamente', {
            variant: 'success',
          });
        }
        setOpenDetailModal(false);
        setReloadRequests(true);
      }
    } catch (err) {
      enqueueSnackbar(err, { variant: 'error' });
    }
  };

  return (
    <Modal
      open={openDetailModal}
      onClose={() => setOpenDetailModal(false)}
      className={classes.modal}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        <Box
          className={classes.tableContainer}
          display='flex'
          justifyContent='space-between'
        >
          <Card>
            <CardContent className={classes.modalContent}>
              {Object.keys(vacationToReview).length > 0 && <Box
                display='flex'
                flexDirection='column'
                flex={1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                >
                  <Box
                    display='flex'
                    mb={2}
                  >
                    <Typography
                      variant='h5'
                    >
                      {`Aprobar o rechazar vacaciones a ${vacationToReview.developer.user.name} ${vacationToReview.developer.user.lastname}`}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    display='flex'
                    flexDirection='column'
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='start'
                      ml={'5%'}
                      mt={'5%'}
                    >
                      <Box width={'49%'}>
                        <Typography variant='h6'>
                          Nombre developer
                        </Typography>
                        <Typography variant='h5'>
                          {`${vacationToReview.developer.user.name} ${vacationToReview.developer.user.lastname}`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant='h6'>
                          Supervisor
                        </Typography>
                        <Typography variant='h5'>
                          {`${vacationToReview.supervisor.user.name} ${vacationToReview.supervisor.user.lastname}`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='start'
                      ml={'5%'}
                      mt={'5%'}
                    >
                      <Box width={'49%'}>
                        <Typography variant='h6'>
                          Fecha inicio
                        </Typography>
                        <Typography variant='h5'>
                          {`${formatDate(vacationToReview.startDate)}`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant='h6'>
                          Fecha fin
                        </Typography>
                        <Typography variant='h5'>
                          {`${formatDate(vacationToReview.endDate)}`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='start'
                      m={'5%'}
                    >
                      <Box>
                        <Typography variant='h6'>
                          Días solicitados
                        </Typography>
                        <Typography variant='h5'>
                          {`${vacationToReview.daysRequested}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>}
              <Divider />
              <Box
                display='flex'
                padding='10px 24px'
              >
                <Box width={'30%'} mr={2}>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: '#22335E',
                    }}
                    onClick={() => setOpenDetailModal(false)}
                    variant='contained'
                  >
                    Cancelar
                  </Button>
                </Box>
                <Box width={'30%'} mr={2}>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: '#D52F2F',
                      '&:hover': {
                        backgroundColor: '#e86464',
                      },
                    }}
                    variant='contained'
                    onClick={(e) => handleReviewRequest(e, REJECTED)}
                  >
                    Rechazar
                  </Button>
                </Box>
                <Box width={'30%'}>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: '#00D293',
                      '&:hover': {
                        backgroundColor: '#80edcc',
                      },
                    }}
                    onClick={(e) => handleReviewRequest(e, APPROVED)}
                    variant='contained'
                  >
                    Aprobar
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Modal>
  );
}

RequestDetailModal.propTypes = {
  openDetailModal: PropTypes.bool.isRequired,
  setOpenDetailModal: PropTypes.func.isRequired,
  vacationToReview: PropTypes.object.isRequired,
  setReloadRequests: PropTypes.func.isRequired,
};
