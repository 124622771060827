import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import assignmentRequest from '../../../requests/api/assignation';
import availabilityRequest from '../../../requests/api/availability';
import developerRequest from '../../../requests/api/developer';
import LastMonthAssignments from './LastMonthAssignments';
import CurrentAssignments from './CurrentAssignments';
import CreateProjectDeveloper from './CreateProjectDeveloper';
import projectsRequest from '../../../requests/api/project';
import useStyles from './styles';
import assignationActions from '../../../actions/assignationActions';
import projectRolesRequest from '../../../requests/api/project_role';
import {
  firstDayOfCurrentMonth,
  lastMonth,
  getNextMonth,
} from '../../../utils/date';
import SearchMonth from './SearchMonth';
import DevelopersBar from './DevelopersBar';
import SearchBar from './SearchBar';
import AvailabilityAndAssignationChart from './AvailabilityAndAssignationChart';

function DevelopersAssignationView() {
  const classes = useStyles();
  const [assignationId, setAssignationId] = useState(0);
  const [developerId, setDeveloperId] = useState(0);
  const [month, setMonth] = useState('');
  const [limit] = useState(100);
  const [lastMonthAssignments, setLastMonthAssignments] = useState([]);
  const [currentMonthAssignment, setCurrentMonthAssignment] = useState([]);
  const [currentMonthAvailability, setCurrentMonthAvailability] = useState([]);
  const [refreshCurrentAssignment, setRefreshCurrentAssignment] = useState(false);
  const [projects, setProjects] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [projectRoles, setProjectRoles] = useState([]);
  const [refreshCharts, setRefreshCharts] = useState(false);
  const dispatch = useDispatch();

  const assignationReduxInfo = useSelector((state) => state.assignation);

  useEffect(() => {
    if (assignationReduxInfo.assignation.developerId) {
      setDeveloperId(assignationReduxInfo.assignation.developerId);
      setMonth(assignationReduxInfo.assignation.month);
    } else {
      setDeveloperId(0);
      const currentDate = new Date();
      const currentDay = currentDate.getDate();
      const firstDayOfMonth = firstDayOfCurrentMonth(currentDate);
      const firstDayOfNextMonth = getNextMonth(firstDayOfMonth);
      const date = currentDay >= 15 ? firstDayOfNextMonth : firstDayOfMonth;
      setMonth(date);
    }
  }, [assignationReduxInfo]);

  useEffect(() => {
    const getDevelopers = async () => {
      const response = await developerRequest.getDevelopersPerPage(0, 0, '', month);
      if (response.success) {
        const developersData = response.data.data.developers;
        const arrayData = Object.values(developersData);
        if (!developerId) {
          setDeveloperId(arrayData[0].id);
        }
        setDevelopers(arrayData);
      }
    };

    if (month && developerId >= 0) {
      getDevelopers();
    }
  }, [developerId, month, refreshCharts]);

  useEffect(() => {
    const getProjects = async () => {
      const response = await projectsRequest.getProjectsPerPage({
        page: 0,
        limit,
        searchClient: '',
        searchProject: '',
        month: '',
      });
      if (response.success) {
        const data = await response.data.data.projects;
        const arrayData = Object.values(data);
        setProjects(arrayData);
      }
    };
    if (month) getProjects();
  }, [refreshCurrentAssignment, refreshCharts, month]);

  useEffect(() => {
    const getLastMonthAssignment = async () => {
      const monthBefore = lastMonth(month);
      const assignation = await assignmentRequest.getAssignationByDeveloperIdAndMonth({
        developerId,
        month: monthBefore,
      });
      if (assignation.success) {
        const data = await assignation.data.data.assignation;
        const arrayData = [data];
        setLastMonthAssignments(arrayData);
      } else {
        setLastMonthAssignments([]);
      }
    };
    const getCurrentMonthAssignment = async () => {
      const assignation = await assignmentRequest.getAssignationByDeveloperIdAndMonth({
        developerId,
        month,
      });
      if (assignation.success) {
        const data = await assignation.data.data.assignation;
        setAssignationId(data.id);
        const arrayData = [data];
        setCurrentMonthAssignment(arrayData);
      } else {
        setAssignationId(0);
        setCurrentMonthAssignment([]);
      }
    };
    if (developerId && month) {
      getLastMonthAssignment();
      getCurrentMonthAssignment();
      dispatch(assignationActions.setAssignation({ developerId, month }));
    }
  }, [developerId, month, refreshCurrentAssignment]);

  useEffect(() => {
    const getProjectRoles = async () => {
      const response = await projectRolesRequest.getProjectRolesPerPage({
        page: 0,
        limit: 100,
        searchValue: '',
      });
      if (response.success) {
        setProjectRoles(response.data.data.projectRoles);
      }
    };
    getProjectRoles();
  }, []);

  useEffect(() => {
    const getDeveloperAvailability = async () => {
      const response = await availabilityRequest.getAvailabilitiesPerPageDeveloperId(
        1,
        developerId,
      );
      if (response.success) {
        const monthNumber = (new Date(month)).getMonth();
        const monthAvailability = response.data.data.availabilities.filter(
          (availability) => new Date(availability.month).getMonth() === monthNumber,
        );
        setCurrentMonthAvailability(monthAvailability);
      }
    };
    getDeveloperAvailability();
  }, [developerId, month]);

  return (
    <Page className={classes.root} title="TubeOps | Asignacion de Developers">
      <Container maxWidth={false}>
        <div className={classes.headerDiv}>
          <Header />
          <SearchMonth
            month={month}
            setMonth={setMonth}
            developerId={developerId}
          />
        </div>

        {developers.length ? (
          <div>
            <div className={classes.mainSquare}>
              <div className={classes.leftColumn}>
                <SearchBar
                  developers={developers}
                  developerId={developerId}
                  setDeveloperId={setDeveloperId}
                />
                <DevelopersBar
                  developers={developers}
                  month={month}
                  developerId={developerId}
                  setDeveloperId={setDeveloperId}
                />
              </div>
              <div className={classes.rightColumn}>
                <AvailabilityAndAssignationChart
                  developerId={developerId}
                  month={month}
                  developers={developers}
                />
                <CreateProjectDeveloper
                  setRefreshCurrentAssignment={setRefreshCurrentAssignment}
                  assignationId={assignationId}
                  projects={projects}
                  setProjects={setProjects}
                  allProjectRoles={projectRoles}
                  month={month}
                  developerId={developerId}
                />
              </div>
            </div>
            <div className={classes.bottomSquare}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <LastMonthAssignments
                    lastMonthAssignments={lastMonthAssignments}
                    month={month}
                    setRefreshCurrentAssignment={setRefreshCurrentAssignment}
                    setProjects={setProjects}
                    developerId={developerId}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CurrentAssignments
                    setRefreshCurrentAssignment={setRefreshCurrentAssignment}
                    setRefreshCharts={setRefreshCharts}
                    currentMonthAssignment={currentMonthAssignment}
                    projects={projects}
                    allProjectRoles={projectRoles}
                    month={month}
                    currentMonthAvailability={currentMonthAvailability}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        ) : null}
      </Container>
    </Page>
  );
}

DevelopersAssignationView.propTypes = {
  match: PropTypes.any,
};

export default DevelopersAssignationView;
