const CONSULTING = 'Consultoría';
const PRODUCT_DESIGN = 'Diseño de producto';
const SOFTWARE_DEVELOPMENT = 'Desarrollo de software';
const SUPPORT_AND_MAINTENANCE = 'Soporte y mantención';
const AGILE_CELL = 'Célula ágil';
const OTHER = 'Otro';

const billServicesTypesList = [
  CONSULTING,
  PRODUCT_DESIGN,
  SOFTWARE_DEVELOPMENT,
  SUPPORT_AND_MAINTENANCE,
  AGILE_CELL,
  OTHER,
];

module.exports = {
  billServicesTypesList,
};
